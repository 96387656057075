<template>
  <el-dialog :visible.sync="dialogVisible"
    @close="clearClose" :close-on-click-modal="false"
    custom-class="add-risk-dialog"
    destroy-on-close title="子项目"
    width="616px">
    <div slot="footer">
      <el-button @click="dialogVisible = false">关 闭</el-button>
      <el-button @click="confirm" type="primary">确 认</el-button>
    </div>
    <div class="form-box" style="display: block;">
      <div class="form-box-title">子项目名称</div>
      <div class="form-box-msg">
        <i class="el-icon-warning" style="color:#FDA835;margin-right: 8px;font-size:20px;"></i>
        可批量录入，每个子项目名称之间用换行隔开
      </div>
      <el-input v-model="form.subjectStr" type="textarea" rows="8" :placeholder="`例：\n子项目1\n子项目2\n子项目3`"
          clearable></el-input>
    </div>
  </el-dialog>
</template>

<script>
import VDistpicker from "v-distpicker";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";
import selectorStaff from "@/views/SystemManagement/components/staff/selectorStaff.vue";
import { OSSDirectPass } from "@/api/oss.js";
import { updateSubProjects, getProjectDetail } from "@/api/basicData";
export default {
  name: "policyProjectDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: ()=> {}
    }
  },
  components: { VDistpicker, EmployeeSelect, selectorStaff },
  data() {
    return {
      dialogVisible: false,
      topLevelId: 0,
      riskType: [],
      participationList: [],
      form: {
        subjectStr: '',
        deptId: '',
        enable: '1',
        order: '',
      },
      rules: {
        deptId: [
          { required: true, message: "请选择所属部门", trigger: "change" },
        ],
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "change" },
        ],
      },
    };
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          getProjectDetail({
            projectId: this.editData.id
          }).then(res=>{
            this.form = res.data;
          })
        } else {
          this.dialogVisible = false;
        }
      }
    }
  },
  mounted() { },
  methods: {
    reset() {
      this.parentOption = [{ id: null, label: null }];
      this.clearClose();
    },
    confirm() {
      // this.$refs["form"].validate((valid) => {
        // if (this.form.subjectStr) {
          let emitData = {
            parentId: this.editData.id,
            bladeUserId: this.$store.state.userInfo.MJUserId,
            deptId: this.editData.deptId,
            subjectStr: this.form.subjectStr
          };
          updateSubProjects(emitData).then(res=>{
            this.$emit('changeList');
            this.clearClose();
          }).catch(err=>{
            console.log(err);
          });
        // }else {
        //   // this.$message.error('请输入项目名称')
        //   this.dialogVisible = false;
        // }
      // });
    },
    clearClose() {
      this.form.subjectStr = '';
      this.dialogVisible = false;
    },
    onSelected(e) {
      this.form.dangerProvince = e.province.value;
      this.form.dangerCity = e.city.value;
      this.form.dangerCounty = e.area.value;
      this.form.riskLocations = e.province.value + e.city.value + e.area.value;
    },
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
    },
    async beforeAvatarUpload(file) {
      this.$refs.uploadaaa.clearFiles()
      let fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        this.$message({
          message: `上传的文件不能大于${ 5 }MB`,
          type: "warning",
        });
        return;
      }
      const url = await OSSDirectPass(file);
      this.form.ProjectRiskFile = url;
    },
  },
};
</script>

<style lang="scss">
.add-risk-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #fff;
    .el-dialog__title {
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }
  .form-box {
    &-title {
      font-size: 14px;
      color: #333333;
    }
    &-msg {
      display: flex;
      align-items: center;
      height: 35px;
      font-size: 14px;
      color: #333333;
      padding: 0 12px;
      margin: 8px 0;
      background: rgba(253,168,53,0.1);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid rgba(253,168,53,0.2);
    }
    ::v-deep textarea{
      white-space: pre-wrap; 
    }       
  }
}
</style>

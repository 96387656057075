<template>
  <el-dialog :visible.sync="dialogVisible"
    @close="clearClose" :close-on-click-modal="false"
    custom-class="add-risk-dialog" :title="!editData.id ? '添加' : '编辑'+editData.projectName"
    width="616px">
    <div slot="footer">
      <el-button @click="dialogVisible = false">关 闭</el-button>
      <el-button @click="confirm" type="primary">确 认</el-button>
    </div>
    <el-form :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="150px"
      size="mini">
      <el-form-item label="项目名称："
        prop="projectName">
        <el-input v-model="form.projectName"
          clearable></el-input>
      </el-form-item>
      <el-form-item label="所属部门："
        prop="deptId">
          <selectorStaff v-model="form.deptId" multiple :isEmptied="false" :checkStrictly="false" @changeCheck="handleChangeDep" />
      </el-form-item>
      <el-form-item label="是否启用："
        prop="enable">
        <el-radio-group v-model="form.enable">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
      </el-form-item>
      <el-form-item label="排序："
        prop="sort">
          <el-input v-model="form.sort" @input="input_num($event, form, 'sort', 99999999999, 0)"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import VDistpicker from "v-distpicker";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";
import selectorStaff from "@/views/SystemManagement/components/staff/selectorStaffNew.vue";
import { insSaveProject, insUpdateProject } from "@/api/basicData";
export default {
  name: "policyProjectDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: ()=> {}
    }
  },
  components: { VDistpicker, EmployeeSelect, selectorStaff },
  data() {
    return {
      dialogVisible: false,
      topLevelId: 0,
      riskType: [],
      participationList: [],
      form: {
        projectName: '',
        deptId: '',
        deptName: '',
        showDeptName: '',
        enable: true,
        sort: 0,
      },
      rules: {
        deptId: [
          { required: true, message: "请选择所属部门", trigger: "change" },
        ],
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "change" },
        ],
      },
    };
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          if (this.editData.id) {
            let data = JSON.parse(JSON.stringify(this.editData));
            if (!Array.isArray(data.deptId)) {
              data.deptId = data.deptId.split(',');
            };
            this.$nextTick(()=>{
              Object.assign(this.form, data);
            })
          }
        } else {
          this.dialogVisible = false;
        }
      }
    }
  },
  mounted() { },
  methods: {
    reset() {
      this.clearClose();
    },
    confirm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const api = this.form.id ? insUpdateProject : insSaveProject;
          let emitData = JSON.parse(JSON.stringify(this.form));
          emitData.deptId = emitData.deptId.toString()
          emitData.bladeUserId = this.$store.state.userInfo.MJUserId;
          api(emitData).then(res=>{
            this.dialogVisible = false;
            this.$emit('changeList');
            this.clearClose();
          }).catch(err=>{
            console.log(err);
          });
          // if (this.actionType == "edit") {
          //   emitData = {
          //     ...this.form,
          //   };
          // }
          // this.$emit("confirm", emitData);
          // this.visible = false;
        }
      });
    },
    clearClose() {
      this.dialogVisible = false;
      this.form = {
        projectName: '',
        deptId: '',
        deptName: '',
        showDeptName: '',
        enable: true,
        sort: 0,
      };
      this.$refs.form.resetFields();
    },
    onSelected(e) {
      this.form.dangerProvince = e.province.value;
      this.form.dangerCity = e.city.value;
      this.form.dangerCounty = e.area.value;
      this.form.riskLocations = e.province.value + e.city.value + e.area.value;
    },
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
    },
    handleChangeDep(e) {
      this.form.deptName = e.name;
      this.form.showDeptName = Array.isArray(e.showName) ? e.showName.toString() : e.showName;
    }
  },
};
</script>

<style lang="scss">
.add-risk-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #fff;
    .el-dialog__title {
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }
  .el-form-item {
    width: calc(100% - 80px) !important;
  }
}
</style>

<template>
  <div class="container">
    <GeneralTable
      ref="GeneralTable"
      :column="column"
      tableName="rickPolicyList"
      :option="option"
      :dataList="dataList"
      :totalNum="totalNum"
      @search-change="getList"
      @export-data="exportData"
      @add-change="
        (operateTitle = '添加'), (editData = {}), (isAddVisible = true)
      "
    >
      <template slot="enable" slot-scope="{ item }">
        <el-tag type="success" v-if="item.enable">启用</el-tag>
        <el-tag type="danger" v-else>禁用</el-tag>
      </template>
      <template slot="operate" slot-scope="{ item }">
        <el-button
          class="polecy-button"
          @click.native.prevent="isAddSubProject = true, editData = item"
          type="text"
          size="small"
          >子项目</el-button
        >
        <el-button
          class="polecy-button"
          @click.native.prevent="editDetail(item)"
          type="text"
          size="small"
          >编辑</el-button
        >
        <el-button
          class="polecy-button"
          @click.native.prevent="handleDel(item)"
          type="text" style="color: #F64A2D;"
          size="small"
          >删除</el-button
        >
      </template>
    </GeneralTable>
    <policyProjectDialog
      v-model="isAddVisible"
      :editData="editData"
      @changeList="changeGetList"
    />
    <addSubProjectDialog
      v-model="isAddSubProject"
      :editData="editData"
      @changeList="changeGetList"
    />
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import policyProjectDialog from "@/views/routerProjectManagement/components/SubProjectList/components/policyProjectDialog.vue";
import addSubProjectDialog from "@/views/routerProjectManagement/components/SubProjectList/components/addSubProjectDialog.vue";
import {
  insProjectPage, insProjectExportFile, deletePolicyProject
} from "@/api/basicData";
import {
  pageProjectList,
  removeBatchById,
  listApplyDownload,
} from "@/api/project";
import {
  getDictList, //获取产品列表
  getDictType, //获取设备列表
} from "@/api/getManagementData.js";
import dayjs from "dayjs";
export default {
  name: "policyProjectManagementList",
  components: { GeneralTable, policyProjectDialog, addSubProjectDialog },
  data() {
    return {
      dataList: [],
      totalNum: 0,
      option: {
        isAdd: true,
        isRefresh: true, // 刷新 @search-change
        isSearch: true,
        isExport: true, // 导入 @import-click
        isSelection: false,
        pageSize: 10,
        searchList: [
          {
            label: "项目名称",
            prop: "projectName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "所属部门",
            prop: "deptName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "启用状态",
            prop: "enable",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: false, text: "禁用" },
              { value: true, text: "启用" },
            ],
            clearable: true,
            placeholder: "请选择",
          },
          {
            label: "创建时间",
            prop: "createTime",
            format: "yyyy.MM.dd",
            formType: "daterange",
            clearable: true,
            prop: ["startDateTime", "endDateTime"],
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "项目名称",
          prop: "projectName",
          isShow: true,
        },
        {
          label: "所属部门",
          prop: "showDeptName",
          isShow: true,
        },
        {
          label: "启用状态",
          prop: "enable",
          isShow: true,
          isSlot: true,
        },
        // {
        //   label: "排序",
        //   prop: "sort",
        //   isShow: true,
        // },
        {
          label: "创建人",
          prop: "createUserName",
          isShow: true,
        },
        {
          label: "创建时间",
          prop: "updateTime",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 180,
          fixed: "right",
          isSlot: true,
        },
      ],
      typesOfConstructionMachineryList: [],
      brandInfoPageList: [],
      partBrandList: [],
      deviceTypeList: [],
      isExpansion: false,
      isImport: false,
      isAddVisible: false,
      isAddSubProject: false,
      editData: {},
      oldSearchData: {},
      operateTitle: "添加",
      machineryEquipmentTypeList: [],
      machineryProductTypeList: [],
      ruleForm: {
        deviceType: "",
        deviceCategory: "",
        topLevelFlag: false,
      },
    };
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    init() {},
    getList(v, pageNum, pageSize, n, type) {
      if (type == "reset") {
        this.ruleForm = {
          deviceType: '',
          deviceCategory: '',
          topLevelFlag: false
        }
      }
      if (v.startDateTime) {
        v.startDateTime =
          dayjs(v.startDateTime).format("YYYY-MM-DD") + " 00:00:00";
        v.endDateTime =
          dayjs(v.endDateTime).format("YYYY-MM-DD") + " 23:59:59";
      }
      this.oldSearchData = Object.assign(
        { ...this.ruleForm },
        { ...v, index: pageNum, pageIndex: pageNum, size: pageSize, fuzzyLookupField: v.keyword }
      );
      insProjectPage(this.oldSearchData).then((res) => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleClassification(e, type) {
      // this[type].includes(e) ? this[type].splice(this[type].findIndex ,1) : this[type].push(e);
      this[type].includes(e) ? (this[type] = []) : (this[type] = [e]);
      this.changeGetList();
    },
    changeGetList() {
      this.$refs.GeneralTable.getList();
    },
    goDetail(e) {
      console.log("子项目");
      // this.$router.push({
      //   path: "/routerProjectManagement/SubprojectDetails?id=" + e.id,
      // });
    },
    exportData(e) {
      insProjectExportFile({
        ...this.oldSearchData,
        ids: e.map((val) => val.id).toString(),
      });
    },
    handleDel(e) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deletePolicyProject({ projectId: e.id }).then((res) => {
          this.changeGetList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    editDetail(e) {
      this.editData = e;
      this.isAddVisible = true;
    },
    // 获取设备类型列表
    getMachineryEquipmentTypeList() {
      getDictType({
        dictType: this.machineryProductTypeList.find(
          (val) => val.dictName == this.ruleForm.deviceType
        ).dictType,
      })
        .then((res) => {
          if (res.flag === 1) {
            this.machineryEquipmentTypeList = res.obj;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择产品类型
    selectMachineryProductType() {
      this.ruleForm.deviceCategory = "";
      this.getMachineryEquipmentTypeList();
    },
  },
};
</script>
<style lang="scss" scoped>
.search-filter {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  .product,
  .brand {
    display: flex;
    .label {
      width: 100px;
      height: 28px;
      line-height: 28px;
      white-space: nowrap;
    }
    .list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .item {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        padding: 0 12px;
        border: 1px solid #4278c9;
        margin: 0 15px 15px 0;
        cursor: pointer;
        border-radius: 4px;
        // &:hover {
        //   color: #4278c9;
        //   background-color: #eff6ff;
        // }
      }
      .check-item {
        color: #ffffff;
        background-color: #4278c9;
        border: 1px solid #4278c9;
      }
    }
  }
  .expansion-box {
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    cursor: pointer;
    font-size: 14px;
    color: #0080ff;
    text-align: center;
  }
}
.enableStatus1 {
}
.enableStatus2 {
}
.slot-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 250px;
    padding: 10px;
    border: 1px solid #dee3e6;
    border-radius: 6px;
    margin: 0 15px 15px 0;
    .img {
      width: 230px;
      height: 230px;
      margin-bottom: 8px;
    }
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .modelNum {
        font-size: 14px;
      }
      .price {
        font-size: 18px;
        color: #fda835;
        font-weight: bold;
      }
    }
    .tags {
      display: flex;
      align-items: center;
      .tag {
        margin-right: 8px;
        padding: 5px 8px;
        background-color: #f2f7fd;
        color: #3470ff;
        border-radius: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
